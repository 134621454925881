<template>
    <div class="pageContol listWrap templateList formCom">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">店铺管理</a>
                    <i>></i>
                    <a
                        href="javascript:;"
                        class="cur-a"
                    >基本信息设置</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <div class="form-box">
                            <el-form
                                ref="ruleForm"
                                label-width="8rem"
                                class="form"
                                :rules="rules"
                                :model="ruleForm"
                            >
                                <el-form-item
                                    label="联系方式"
                                    prop="mobile"
                                >
                                    <el-input
                                        v-model="ruleForm.mobile"
                                        size="small"
                                        maxlength="11"
                                        show-word-limit
                                        oninput="value=value.replace(/[^\d]/g,'')"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item
                                    label="微信二维码"
                                    prop="wechatUrl"
                                >
                                    <el-upload
                                        :on-change="uploadBanner"
                                        :before-upload="$beforeAvatarUpload"
                                        :http-request="$requestMine"
                                        :show-file-list="false"
                                        class="img-el-upload"
                                        action
                                        accept="image/png, image/gif, image/jpg, image/jpeg"
                                    >
                                        <el-image
                                            :src="ruleForm.wechatUrl || require('@/assets/develop.png')"
                                            fit="contain"
                                            class="imgCenter"
                                        ></el-image>
                                    </el-upload>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="btn-box flexcc">
                            <el-button
                                class="bgc-bv"
                                @click="doAddSave()"
                                style="width:150px"
                            > 保 存 </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "shop/Info",
    data() {
        return {
            // 基本信息
            ruleForm: {
                mobile: "",
                wechatKey: "", // 文章封面 key
                wechatUrl: "",
            },
            rules: {
                mobile: [
                    {
                        required: true,
                        message: "请输入联系方式",
                        trigger: "blur",
                        // validator: this.$validatePhone,
                    },
                ],
                wechatUrl: [
                    {
                        required: true,
                        message: "请上传课程封面",
                        trigger: "change",
                    },
                ],
            },
            appContactId: "",
        };
    },
    watch: {},
    created() {
        this.getDetail();
    },
    computed: {},
    methods: {
        //新增
        doAddSave() {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    this.$post(
                        "/biz/base/info/saveOrUpdate",
                        {
							// infoId:"",
                            mobile: this.ruleForm.mobile,
                            // wechatUrl: this.ruleForm.wechatUrl,
                            wechatKey: this.ruleForm.wechatKey,
                        },
                        3000,
                        true,
                        2
                    ).then((res) => {
                        if (res.status == "0") {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.getDetail();
                        } else {
                            this.$messge.error(res.message);
                        }
                    });
                }
            });
        },
        //编辑回显
        getDetail() {
            this.$post("/biz/base/info/getInfo", {}, 3000, true, 2).then(
                (res) => {
                    // ;
                    if (res.status == "0") {
                        this.ruleForm.mobile = res.data.mobile;
                        this.ruleForm.wechatUrl = res.data.wechatUrl;
                        this.ruleForm.wechatKey = res.data.wechatKey;
                    }
                }
            );
        },
        //图片上传
        uploadBanner(res) {
            let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
            let formData = new FormData();
            formData.append("folder ", "TEMP");
            formData.append("file ", res.raw);
            formData.append("fileType ", extension);
            formData.append("isPublic ", true);
            const isLt2M = res.size / 1024 / 1024 < 3;
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 3MB!");
                return false;
            } else {
                this.$Postformat("/sys/upload", formData)
                    .then((result) => {
                        this.ruleForm.wechatUrl = result.data.fileURL || "";
                        this.ruleForm.wechatKey = result.data.fileKey || "";
                    })
                    .catch(() => {
                        setTimeout(() => {
                            this.$message({
                                type: "warning",
                                message: "上传失败",
                            });
                        }, 300);
                    });
            }
        },
    },
};
</script>
<style lang="less" scoped>
.form-box {
    margin-bottom: 100px;
}
</style>
